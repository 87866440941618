.btn-tigerlily {
  background-color: #e15d44 !important;
  color: #000 !important;
  opacity: 0;
}

.register-and-login-btn::before {
  box-sizing: border-box;
  background: #01cab8;
  border-radius: 30px;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: scale(0);
  transition: all 0.5s ease !important;
  width: 100%;
  z-index: -1;
}

.register-and-login-btn {
  background: #005c74;
  border: 2px solid #005c74;
  color: #fff !important;
  transition: all 0.3s linear !important;
  border-radius: 30px;
  display: inline-block;
  font-size: 17px;
  font-weight: 600;
  padding: 14px 32px;
  position: relative;
  z-index: 1;
}

.register-and-login-btn:hover {
  background: #01cab8;
  border: 2px solid #01cab8;
}

.sidebar a.active {
  background-color: #ffffff11;
  color: white !important;
  /* border-left: 8px solid #00b0f0; */
  opacity: 1 !important;
}

.nav-link:hover {
  background-color: #ffffff11;
  opacity: 1 !important;
}

.acc-activation:hover {
  background-color: rgba(0, 176, 240, 0.5) !important;
  cursor: pointer;
}

#pays-count-badge {
  margin: 7px 0 0 -8px;
}

.user-nav-dropdown div {
  position: absolute !important;
}

.user-nav-dropdown a {
  padding: 0 !important;
}

.home-hospital-wrapper {
  width: 100%;
}

.doctors-hospital-wrapper {
  width: 60%;
}

.select-hospital {
  width: 100%;
}

.doctors-select-hospital {
  width: 100%;
}

/* .home-select-hospital {
  max-width: 60%;
} */

.page-nav a.active {
  border-bottom: 6px solid #00b0f0;
}

.page-nav-count {
  /* padding: 0.25rem; */
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 1.5rem;
  width: 1.5rem;
  font-weight: bold;
  color: white;
  background-color: #2f5496 !important;
}

.custom-form-nav a.active {
  background-color: #00b0f0;
}

.custom-form label::after,
.modal-form label::after,
.accreditationBodyLabel::after {
  content: " *";
  color: red;
  font-size: 1rem;
  font-weight: bold;
}

.custom-form input {
  height: 2.5rem;
  /* width: 50%; */
  padding: 0 4px;
  border: 2px solid #b3c6e7;
  border-radius: 0;
}

.custom-form button,
.modal-submit-btn {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

input:disabled {
  background-color: #dddddd;
}

/* .custom-form  */
input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
  height: 100%;
  width: 0;
}

/* .custom-form  */
input[type="file"] {
  border: none;
  width: 100%;
}

.file-ip-div {
  /* width: 50%; */
  border: 2px solid #b3c6e7;
  display: inline-flex;
  align-items: center;
  position: relative;
  z-index: 0;
}

.upload-icon {
  position: absolute;
  right: 0;
  z-index: -1;
}

.facilities-update-form label::after,
.beds-count label::after,
.cin label::after,
.not-req::after {
  content: "" !important;
}

.doctor-register-btn:disabled {
  background-color: #3a3838 !important;
}

.subscription-rx-opd-services {
  background-color: #00b0f0;
  color: white;
}

.services {
  justify-content: space-evenly !important;
}

.services .service {
  background-color: #002060;
  border-radius: 1rem;
  width: 35% !important;
  padding: 1rem;
}

.service-list ul li {
  list-style-type: "\2713";
  padding-left: 0.5rem;
}

.table-responsive {
  max-height: 70% !important;
  overflow: auto;
}

.t-head {
  position: sticky;
  top: 0;
  background-color: #5b9bd5;
  color: white;
  border-color: white;
}

.t-body {
  border-color: white !important;
}

.t-body tr:nth-of-type(odd) {
  background-color: #d0deef;
}

.t-body tr:nth-of-type(even) {
  background-color: #e9eff7;
}

.table-btn {
  padding: 0.35rem 1.25rem;
  font-weight: normal;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
}

.table-update-btn {
  background-color: #2f5496;
}

.table-remove-btn {
  background-color: red;
}

.table-remove-btn:disabled {
  background-color: rgba(255, 0, 0, 0.5);
}

.schedule-modal-odd-row {
  background-color: #d0deef;
}

.react-datepicker-wrapper input {
  border: 2px solid #b3c6e7;
  padding: 0.2rem 0.8rem;
  width: 100%;
}

.form-check input {
  height: 100%;
  width: 3rem !important;
}

.form-nav-item__number {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  padding: 0.25rem;
}

.form-nav-item-active {
  background-color: rgb(25, 118, 210);
  color: white;
}

.min-w-50 {
  min-width: 50%;
}

.min-h-100 {
  min-height: 100% !important;
}

.facility-update-form label::after {
  content: "";
}

.sidebar-pos {
  position: absolute;
  z-index: 9999999999;
}

.sidebar-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}

.facility-contact-2 label::after {
  content: "" !important;
}

#invoice-print {
  display: none;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 99999999999999 !important;
}

.vr {
  width: 6px !important;
}

/* canvas {
  aspect-ratio: 150/100px !important;
} */

.toast-modal {
  z-index: 9999 !important;
}

.toaster-backdrop {
  z-index: 9998 !important;
}

.pricing {
  background: #007bff;
  background: linear-gradient(to right, #0062e6, #33aeff);
}

.pricing .card {
  border: none;
  border-radius: 1rem;
  transition: all 0.2s;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.pricing hr {
  margin: 1.5rem 0;
}

.pricing .card-title {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
}

.pricing .card-price {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
}

.pricing .card-price .period {
  font-size: 0.8rem;
}

.pricing ul li {
  margin-bottom: 1rem;
}

.pricing .text-muted {
  opacity: 0.7;
}

.pricing .btn {
  font-size: 90%;
  border-radius: 5rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  padding: 0.7rem 1.2rem;
  transition: all 0.2s;
}

.points-history-table {
  vertical-align: middle;
  border-collapse: collapse;
  width: 100%;
  font-size: 0.85rem;
}

.points-history-table tbody tr:last-child,
.points-history-table tbody tr:first-child {
  border: none;
}

.point-history-data {
  border-collapse: collapse;
  text-align: center;
  border-top: 3px solid #b3c6e7;
  border-bottom: 3px solid #b3c6e7;
}

.point-history-data td {
  width: 32%;
}

.earn-reward-banner:hover {
  /* cursor: pointer; */
  margin-top: -0.25rem;
  /* margin-bottom: 0.25rem; */
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
}

.earn-rewards-banner-desc {
  width: 100% !important;
}

.quickstart-modal {
  width: 97% !important;
}

.no-patient-modal {
  z-index: 2009 !important;
}

.no-patient-modal-backdrop {
  z-index: 2008 !important;
}

.video-container {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: "";
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.changepass-modal {
  width: 95% !important;
  margin: 0 auto !important;
}

.notification-modal-wrapper {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.refer-modal {
  height: 90% !important;
  width: 90% !important;
}

.notification-action-button {
  display: block !important;
}

.btn-close {
  color: white !important;
}

.referral-img {
  height: 15vh !important;
  width: 100% !important;
}

.referral-note {
  width: 100% !important;
}

.referral-btn {
  width: 30% !important;
}

.update-schedule-modal button {
  margin: 0 !important;
  padding: 0 !important;
  position: absolute;
  right: 20px;
}

.update-user-modal {
  width: 50%;
}

/* Hover Effects on Card */

@media (min-width: 992px) {
  .pricing .card:hover {
    margin-top: -0.25rem;
    margin-bottom: 0.25rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
  }

  .home-hospital-wrapper {
    width: 30%;
  }

  /* .home-select-hospital {
    width: 30%;
  } */
}

@media (min-width: 768px) {
  .update-user-modal {
    width: 100% !important;
  }

  /* .home-select-hospital {
    width: 30%;
  }

  .appointment-select-hospital,
  .invoices-select-hospital {
    width: 60%;
  }

  .payments-select-hospital,
  .doctors-select-hospital {
    width: 30%;
  } */

  /* .custom-form input::after {
    content: " \1F6C8" !important;
  } */

  .referral-img {
    height: 100% !important;
    width: 20% !important;
  }

  .referral-note {
    width: 65% !important;
  }

  .referral-btn {
    width: auto !important;
  }

  .notification-action-button {
    display: flex !important;
  }

  .reward-details-modal {
    height: 85%;
    width: 65% !important;
  }

  .points-history-table {
    width: 75%;
    font-size: 1rem;
  }

  .earn-rewards-banner-desc,
  .changepass-modal {
    width: 75% !important;
  }

  .quickstart-modal {
    width: 60% !important;
  }

  .refer-modal {
    width: 60% !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin: auto !important;
  }
}

@media (min-width: 576px) {
  .calendar-arrow {
    left: 50% !important;
  }

  .sidebar-pos {
    position: static;
  }
}

@media print {
  html,
  body {
    height: 100vh !important;
    width: 100vw !important;
    background-color: white;
    color: white;
    overflow: hidden;
  }

  #invoice-print {
    display: block;
  }
}

.patient-profile-ele {
  margin-top: -1.8rem !important;
}

#fetchDetailsbtn {
  background: #007bff;
}

.modalBackground {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer {
  width: 350px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.modalContainer .title {
  font-weight: bold;
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer .footer button {
  width: 130px;
  height: 40px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  cursor: pointer;
}

#cancelBtn {
  background-color: #8ab2d6;
}

#continueBtn {
  background-color: #5b9bd5;
}

#backBtn {
  color: black;
  text-decoration: none;
  cursor: auto;
}

#checkOption3,
#checkOption2,
#checkOption1 {
  background-color: #b3c4d3;
  width: 100px;
}

.ms--12,
#dob {
  margin-left: -12px;
}

.w-95 {
  width: 95% !important;
}

.time-period-heading {
  background-color: rgb(224, 224, 224);
  color: rgb(33, 33, 33);
  padding: 8px;
  margin-bottom: 16px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.time-slot-btn {
  font-size: 0.85rem !important;
  color: #388e3c;
  border: 1px solid #388e3c;
  border-radius: 4px;
  transition: all 0.2s linear;
}

.time-slot-btn:disabled {
  color: #9e9e9e;
  border: 1px solid #9e9e9e;
  background-color: #eeeeee;
}

.time-slot-btn:hover {
  background-color: #388e3c;
  color: white;
}

.time-slot-btn:disabled:hover {
  color: #9e9e9e;
  background-color: #eeeeee;
}

.active-time-slot {
  background-color: #388e3c !important;
  color: white !important;
}

.walk-in-switch input {
  width: 23% !important;
  height: 25px;
}

.whole-screen-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999999999999999999999999;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.45);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  /* opacity: 0.5; */
  /* filter: alpha(opacity=50); */
}

.calendar-datepicker > *,
.calendar-datepicker input {
  outline: none;
}

.cal-event-date-popper {
  z-index: 666;
}

/* .fc-header-toolbar {
  margin: 0 !important;
} */
/* 
thead .fc-scroller {
  overflow: hidden !important;
}

thead .fc-scroller .fc-col-header-cell-cushion { */
/* text-decoration: none !important; */
/* color: black !important;
} */

/* .no-scrollbar {
  overflow: hidden;
} */

.booked-appointment {
  border: none !important;
  background-color: #0d6efd !important;
  color: white !important;
}

.completed-appointment {
  border: none !important;
  background-color: #198754 !important;
  color: white !important;
}

.not-confirmed-appointment {
  border: 1px solid #dc3545 !important;
  background-color: white !important;
  color: #dc3545 !important;
}

.cancelled-appointment {
  border: 1px solid #6c757d !important;
  background-color: white !important;
  color: #6c757d !important;
}

.fc-timegrid-event:hover {
  cursor: pointer !important;
}

.appointment-lineage {
  min-height: 160px;
  overflow-x: scroll;
  overflow-y: hidden;
}

.timeline-steps {
  display: flex;
  /* justify-content: center; */
  /* flex-wrap: wrap; */
}

.timeline-steps .timeline-step {
  margin-top: 1.5rem;
  align-items: center;
  display: flex;
  /* flex-direction: column; */
  position: relative;
  margin: 1rem;
}

/* @media (min-width: 768px) { */
.timeline-steps .timeline-step:not(:last-child):after {
  margin-top: 1.5rem;
  content: "";
  display: block;
  border-top: 0.25rem solid #5b9bd5;
  width: 3.46rem;
  position: absolute;
  left: 7.5rem;
  top: 2.6rem;
}
.timeline-steps .timeline-step:not(:first-child):before {
  margin-top: 1.5rem;
  content: "";
  display: block;
  border-top: 0.25rem solid #5b9bd5;
  width: 3.7125rem;
  position: absolute;
  right: 7.5rem;
  top: 2.6rem;
}
/* } */

.timeline-steps .timeline-content {
  width: 10rem;
  text-align: center;
}

.timeline-steps .timeline-content .inner-circle {
  margin-top: 1rem;
  border-radius: 1.5rem;
  height: 1rem;
  width: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #5b9bd5;
}

.timeline-steps .timeline-content .inner-circle:before {
  content: "";
  background-color: #5b9bd5;
  display: inline-block;
  height: 3rem;
  width: 3rem;
  min-width: 3rem;
  border-radius: 6.25rem;
  opacity: 0.5;
}

.appointment-lineage-time {
  font-size: 0.75rem !important;
  margin-bottom: 0.6rem !important;
}

.aaaa {
  margin-bottom: 1rem;
}

.fc-button-active {
  z-index: 0 !important;
}

.fc-button {
  text-transform: capitalize !important;
}
.searchbar {
  width: 100%;
  border: 1px solid #f4f4f4;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  margin: 1.4rem auto 0 auto;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  margin-bottom: 1rem;
}

.searchbar input {
  outline: none;
  width: 100%;
  border: none;
  filter: none;
}
@media (max-width: 767px) {
  .fc-toolbar.fc-header-toolbar {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    flex-shrink: 0 !important;
    width: 100% !important;
  }
  .fc-toolbar.fc-header-toolbar .fc-start {
    order: 3;
  }
  .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk {
    margin: 3px 0 !important;
    order: 1;
  }
  .fc-toolbar.fc-header-toolbar .fc-end {
    order: 2;
  }
}

.fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .fc-book-button {
  background-color: #005c74;
  border-color: #005c74;
  color: white;
}

/* Hide the default scrollbar */
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  transition: background-color 0.3s;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.custom-scrollbar {
  overflow-y: scroll;
}
.custom-padding {
  padding-inline: 8px;
  padding-block: 5px;
}
/* .mobileTimerPicker_label {
  font-size: 12px;
  top: unset !important;
  bottom: 1.3rem;
  left: 1rem;
} */
.mobileTimerPicker {
  max-width: 120px;
}

/* .mobileTimerPicker label {
  font-size: 12px;
  top: unset !important;
  bottom: 1.3rem;
  left: 1rem;
} */
.mobileTimerPicker input:disabled {
  background-color: white;
}

/* .mobileTimerPicker .MuiInputLabel-shrink {
  bottom: 0.8rem;
  left: 0.5rem;
} */

.mobileTimerPicker > div {
  max-height: 30px;
}

/* .mobileTimerPicker__label {
  position: absolute;
  font-size: 10px;
  background-color: white;
  top: -0.5rem;
  right: 1rem;
  text-transform: capitalize;
} */

.mobileTimerPicker__switch > span {
  height: 20px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.mobileTimerPicker__switch > span > span > span {
  height: 16px !important;
  width: 16px !important;
}
.MuiTablePagination-displayedRows {
  margin-top: 15px;
}
.MuiTablePagination-selectLabel {
  margin-top: 15px;
}
.orderHeader{
  display:flex;
    text-align: center;
  margin-bottom: 0;
  margin-left: 30px;
max-width:1075px;
}
.form-controlname{
max-width: 550px;
margin: 10px 30px 10px;
}

.Header2{
 max-width: 550px; 
 max-height: 60px;
 padding: 0;
  display:flex;
justify-content: center;
  margin: 10px 30px 10px 31px;
}  
.ArrayRow
{ 
  max-width: 980px;
   max-height: 60px;
   display:flex;
   margin-left: 30px; 
   
  }

.button1 {
  width: 50px;
  height: fit-content;
  border-radius: 5px;
  margin: 3px;
  background-color:#009698;
  border-color: rgb(221, 214, 214);
  color: white;
}
.form-control1{
  margin:0px;
  padding:0px;
 }

.inputWidth{
  width:600px;
}
.editableTotalAlign{
  width: 800px;

text-align: center;
}

@media screen and (max-width: 560px) {
#customer_name{width:250px;
  float: left;

}  }
 @media screen and (max-width: 560px) {
  .orderHeader h5 ,.csname{
    max-width: 500px;
font-size: small;
    
  }  }   

  @media screen and (max-width: 560px) {
    .mediafontcontrol,.ArrayRow, .Header2 {
      max-width: 530px;
      font-size: smaller;
      
    }  }   

    .switch-icons{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 95px;
      .texts{
        --bs-text-opacity: 1;
        color: rgba(var(--bs-success-rgb),var(--bs-text-opacity))!important;
        display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    margin-top: -23px;
   padding-left: 89px;
      }
    }
    .valid-message {
      color: green;
    }
    
    .invalid-message {
      color: red;
    }
    .facili{
      border-radius: 15px;
      resize: none;
    }

    
      .plns{
      display: flex;
      flex-direction: row;
      margin-top: 13px;
      flex-wrap: wrap;
      justify-content: space-between; /* Adjust alignment as needed */
    
      .card{
          margin: 10px;
          margin-bottom: 4px;
          border: 2px solid rgb(175, 157, 157);
          border-radius: 40px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add box shadow */
          padding: 20px; /* Add padding for content spacing */
          background-color: #ffffff; /* Add background color */
          transition: box-shadow 0.3s ease; /* Add transition effect */
           width: 400px;
           width: calc(33.33% - 20px); /* Adjust width as needed */

          &:hover {
            box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15); /* Adjust box shadow on hover */
          }
        
        
              h4{
              text-align: center;
              border: 2px solid #199a8e;
              border-width: 7px;
              background-color:#199a8e;
              color: #fff;
              width: 154px;
              margin: auto;
          }
          h3{
              text-align: center;
          }
          p{
              text-align: center;
              color: gray;
          }
          span{
  
                cursor: pointer;
                margin-bottom: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
          }
          Button{
              display: flex;
              align-items: center;
              justify-content: center;
              margin: auto;
          }
      } 
  }
  .btn-booked {
    background-color: transparent !important;
    border: 1px solid red !important;
    color: red !important;
    margin-left: -2rem;
  }
  .btn-successss{
    background-color: transparent !important;
    border: 1px solid green !important;
    color: green !important;
    /* margin-left: -2rem; */
  }
  .btn-primaryii{
    background-color: transparent !important;
    border: 1px solid #0d6efd !important;
    color: #0d6efd !important;
    /* margin-left: -2rem; */
  }

  .custom-switch.form-switch {
    padding-left: 2.5em;
  }
  
  .custom-switch .form-check-input {
    height: 1.5em;
    width: 3em;
    margin-top: 0.2em;
  }
  
  .custom-switch .form-check-input:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
  }
  .custom-switch.form-switch {
    padding-left: 3em;
  }
  
  .custom-switch .form-check-input {
    height: 1.6rem;
    width: 3rem !important;
    margin-top: 0.25em;
  }
  .appointment__date__record{
    align-items: center;
    background: #199a8e;
    border-radius: 12px;
    box-shadow: 0 4px 4px #00000021;
    color: #fff;
    display: flex;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    gap: .5rem;
    line-height: 13px;
    padding: 8px 12px;
  }
  .prescription__pdf{
    max-height: 500px;
    overflow-y: scroll;
    position: relative;
  }
