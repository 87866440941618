.facilityImagesWrapper {
    width: 100%;
    height: 100px;
  }
  .facilityImages {
    width: 100%;
    overflow-x: scroll;
    display: flex;
  }
  .facilityImageContainer {
    position: relative;
    text-align: center;
    color: white;
    margin-bottom: 10px;
    margin-right: 15px;
  }
  .removeFacilityImage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: red;
  }