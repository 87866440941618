* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.gmnoprint,
.gm-style-cc {
  display: none;
}
.pac-container {
  z-index: 10000 !important;
}
.clinical-findings {
  max-width: fit-content;
  padding: 1rem;
  margin: auto 0.5rem;
  min-height: max-content;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #ffffff;
  border-radius: 10px;
  position: relative;
}

.clinical-findings .mui-slider-mark-label {
  background: transparent !important;
  box-shadow: none !important;
}

.clinical-findings-bubbles {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.clinical-findings-bubbles-disable {
  background: #898a8d !important;
}

.clinical-findings-bubbles-button {
  font-size: 10px;
  text-align: center;
  width: fit-content;
  text-transform: capitalize !important;
}

.clinical-findings-container {
  /* margin-top: 2rem; */
}

.clinical-findings-heading {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}

.clinical-findings-slider-heading {
  font-size: 14px;
  font-weight: 500;
}
.duration-slider{
  margin: auto;
  width: 90%;
}

.duration-slider-name {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
}

.duration-slider-manual {
  margin: 0.5rem auto;
  width: 90%;
  display: flex;
  flex-direction: column;
}

.duration-slider-manual p {
  margin: 0.3rem 0 0.5rem 0;
  font-size: 11px;
  font-weight: 600;
}

.duration-slider-manual input {
  border: 0;
  width: 90%;
  font-weight: 700;
  font-size: 14px;
  border-bottom: 0.1rem solid #898a8d;
  padding-left: 1rem;
  outline: none;
}

.duration-slider-submit{
  margin: 0.6rem 0 0 0;
  border-radius: 6px;
  padding: 8px 20px;
  width: fit-content;
  align-self: end;
}
.static-disable {
  background-color: #d9d9d9 !important;
  pointer-events: none !important;
  color: black !important;
}

.static-button {
  all: unset;
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  padding: 15px 10px;
  background: #199a8e;
  border-radius: 32px;
  color: #ffffff;
  width: 40%;
  display: flex;
  justify-content: center;
}