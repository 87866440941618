.Pcontainer{
  position: absolute;
  top : 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  box-shadow: 5px grey;

}
.Container2{
    width: 300px;
    height: 350px;
    background-color: white; 
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
box-sizing: border-box;
border-radius: 55px;    
  }
  .Head1{
    margin-top: 20px;
text-align: center;  
  }
  .Head2{
    font-size: x-small;
    color:blueviolet;
    text-decoration: solid;
    
  }
  .Countrycode{
   display: flex; 
    margin-top: 20px;
   
  }
  .box2{
    text-align:left;
    padding: 5px;
    margin: 4px;
    width: 150px;
    border: 1px solid rgb(186, 184, 184);
    border-radius: 5px;
  }
  .box1{
   padding: 3px; 
    text-align: right;
  margin: 4px;
    width: 35px;
    border: 1px solid  rgb(186, 184, 184);
    border-radius: 5px;
  }
  .mailbox
  {
    margin: 4px;
    width: 195px;
    padding: 3px;
    border: 1px solid  rgb(186, 184, 184);
    border-radius: 5px;
  }
  
  .sendpaymentlinkfooter{
    width: 100px;
    height: 26px;
    border-radius: 5px;
    background-color: #009698;
    align-items : center;
    margin: 14px;
    color: white;
  }
  
  
  .imgsendpaymentlink1{
    width:45px;
    height: 30px;
    margin-top: 5px;
    margin-bottom: 5px;;
    margin-right: 8px;
    padding-left: 20px;

  }
  #imp{
    display: flex;
    flex-direction:row;
    justify-content:space-evenly;
  }
  

  .Notificationpopup
  {
width:350px;
height: 150px;
padding:15px;
text-align: center;
color: grey;
background-color: white;
border-radius: 20px;
box-shadow: 2px lightgrey;
  }