.input {
  border-width: 1px !important;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.23) !important;
  border-radius: 4px;
  color: #263d50;
  height: 3.4375em !important;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  cursor: text;
  width: 60%;
  box-shadow: 0 2px 6px #0000004d;
  text-overflow: ellipsis;
  height: 29px !important;
  padding: 0 11px 0 13px;
  margin-top: 10px;
  outline: none !important;
}

.input:focus {
  border-color: #1976d2;
  border-width: 2px;
}
