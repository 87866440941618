.facilityLogoWrapper {
  position: relative;
}

.facilityLogoIcon {
  background-color: grey !important;
  opacity: 0.3;
}

.facilityLogoIcon:hover {
  background-color: grey !important;
  opacity: 0.6;
  -webkit-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
}

.facilityLogoIcon svg {
  color: white;
}

.facilityImagesWrapper {
  width: 100%;
  height: 100px;
}
.facilityImages {
  width: 100%;
  overflow-x: scroll;
  display: flex;
}

.facilityImageContainer {
  position: relative;
  text-align: center;
  color: white;
  margin-bottom: 10px;
  margin-right: 15px;
}

.removeFacilityImage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: red;
}

/* .facilityImages::-webkit-scrollbar {
  width: 100px !important;
}

.facilityImages::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.facilityImages::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
} */
/* .facilityImages ::-webkit-scrollbar-button {
  background: #ccc;
}
.facilityImages ::-webkit-scrollbar-track-piece {
  background: #888;
}
.facilityImages ::-webkit-scrollbar-thumb {
  background: #eee;
} */

@media (max-width: 576px) {
  .facilityImages {
    height: 100px;
    display: block;
  }
}
