.rootContainer {
  max-width: 480px;
  cursor: not-allowed !important;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.rootContainer > * {
  cursor: not-allowed !important;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.facilityName {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 700 !important;
}
.address {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.tickerTextWrapper {
  box-shadow: 0 2px 10px #888;
  /* width: 100%; */
  padding: 10px 15px;
  overflow: hidden;
  /* position: relative; */
  background-color: #95e4e8;
}

.tickerText {
  font-size: 20px;
  white-space: nowrap;
  animation: moveLeftToRight 10s linear infinite;
}

.welcomeTitle {
  font-size: 20px !important;
  text-align: center;
  margin-bottom: 15px;
  font-weight: 550 !important;
  color: #10828e !important;
}

.welcomeMessage {
  text-align: center;
  margin-top: 0;
  color: grey !important;
}

.imageSlider {
  display: flex;
  overflow: hidden;
  padding: 15px 0;
  animation: moveLeftToRight 20s linear infinite;
}

/* .slide {
  flex-shrink: 0;
  width: inherit;
  max-width: 30%;
  margin-right: 10px;
  white-space: nowrap;
} */
.slide img {
  height: 300px !important;
  width: 300px !important;
  max-width: 120px !important;
  max-height: 120px !important;
}

.offeredServices {
  cursor: not-allowed !important;
}

.offeredServices button {
  display: block;
  position: relative;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 600;
  color: #10828e;
  box-shadow: 0 2px 10px #888;
  cursor: not-allowed !important;
}

.offeredServices button:disabled {
  color: #10828e;
}

.offeredServices img {
  position: absolute;
  left: 20px;
  top: 8px;
  max-width: 44px;
}

/* .footer {
  bottom: 0;
  width: 100%;
  margin-top: auto;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background-color: transparent;
} */

.footerTerms {
  font-family: "Inter";
  font-size: 10px !important;
  border: none;
  font-weight: 500;
  text-decoration: underline;
}

.footerLogo {
  max-width: 45px;
  vertical-align: middle;
}

.footer-link {
  gap: 0.8rem;
}

@media (max-width: 576px) {
  .rootContainer {
    max-width: 100%;
  }
}

@keyframes moveLeftToRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
